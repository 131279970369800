import React, { useLayoutEffect, useState } from 'react';
import logo from '../Resources/PabhaLogo.png';

function SiteHeader(props) {
  const [isWide, setIsWide] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setIsWide(window.innerWidth > 1000);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const redirectPanna = () => {
    window.open('https://pannajiva.com/', '_self');
  };

  return (
    <div
      style={{
        maxheight: 100,
        height: 100,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {isWide && (
        <div
          style={{
            height: 100,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            overflow: 'hidden',
          }}
        >
          <p
            style={{
              fontSize: '200%',
            }}
          >
            Prabhakara Jimmy Quek's Artwork Collection
          </p>
        </div>
      )}
      <div
        style={{
          height: 100,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          marginRight: 50,
          cursor: 'pointer',
        }}
        onClick={redirectPanna}
      >
        <img src={logo} height="30" alt="pabhaLogo" />
      </div>
    </div>
  );
}

export default SiteHeader;
